<template>
  <g>
    <!-- <g v-if="showVariant === 'outside'"> -->
      <svg:style>
      .cls-101,
      .cls-110,
      .cls-111,
      .cls-112,
      .cls-113,
      .cls-114,
      .cls-115,
      .cls-116,
      .cls-117,
      .cls-118,
      .cls-119,
      .cls-102,
      .cls-120,
      .cls-103,
      .cls-104,
      .cls-105,
      .cls-106,
      .cls-107,
      .cls-108,
      .cls-109 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-102 {
        fill: none;
      }

      .cls-103 {
        fill: url(#handle-gradient);
      }

      .cls-110,
      .cls-111,
      .cls-112,
      .cls-113,
      .cls-114,
      .cls-115,
      .cls-116,
      .cls-117,
      .cls-118,
      .cls-119,
      .cls-120,
      .cls-104,
      .cls-105,
      .cls-106,
      .cls-107,
      .cls-108,
      .cls-109 {
        stroke-linecap: square;
      }

      .cls-104 {
        fill: url(#inox-gradient);
      }

      .cls-105 {
        fill: url(#inox-gradient-2);
      }

      .cls-106 {
        fill: url(#inox-gradient-5);
      }

      .cls-107 {
        fill: url(#inox-gradient-4);
      }

      .cls-108 {
        fill: url(#inox-gradient-5);
      }

      .cls-109 {
        fill: url(#inox-gradient-6);
      }

      .cls-110 {
        fill: url(#inox-gradient-7);
      }

      .cls-111 {
        fill: url(#inox-gradient-8);
      }

      .cls-112 {
        fill: url(#inox-gradient-9);
      }

      .cls-113 {
        fill: url(#inox-gradient-10);
      }

      .cls-114 {
        fill: url(#inox-gradient-11);
      }

      .cls-115 {
        fill: url(#inox-gradient-12);
      }

      .cls-116 {
        fill: url(#inox-gradient-13);
      }

      .cls-117 {
        fill: url(#glass-pattern);
      }

      .cls-118 {
        fill: url(#glass-pattern);
      }

      .cls-119 {
        fill: url(#glass-pattern);
      }

      .cls-120 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient
      id="handle-gradient"
      :x1="doorLeftWidth1 + 11.16"
      :y1="doorTopHeight1 + 148.47"
      :x2="doorLeftWidth1 + 16.75"
      :y2="doorTopHeight1 + 148.47"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient
      id="inox-gradient"
      :x1="doorLeftWidth1 + 85.92"
      :y1="doorTopHeight1 + 70.65"
      :x2="doorLeftWidth1 + 117.71"
      :y2="doorTopHeight1 + 70.65"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-2"
      data-name="inox-gradient"
      :x1="inoxX"
      :y1="doorTopHeight1 + 70.65"
      :x2="doorLeftWidth1 + 53.69"
      :y2="doorTopHeight1 + 70.65"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-3"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 85.92"
      :y1="doorTopHeight1 + 122.36"
      :x2="doorLeftWidth1 + 117.71"
      :y2="doorTopHeight1 + 122.36"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-4"
      data-name="inox-gradient"
      :x1="inoxX"
      :y1="doorTopHeight1 + 122.36"
      :x2="doorLeftWidth1 + 53.69"
      :y2="doorTopHeight1 + 122.36"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-5"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 85.92"
      :y1="doorTopHeight1 + 174.07"
      :x2="doorLeftWidth1 + 117.71"
      :y2="doorTopHeight1 + 174.07"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-6"
      data-name="inox-gradient"
      :x1="inoxX"
      :y1="doorTopHeight1 + 174.07"
      :x2="doorLeftWidth1 + 53.69"
      :y2="doorTopHeight1 + 174.07"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-7"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 85.92"
      :y1="doorTopHeight1 + 225.66"
      :x2="doorLeftWidth1 + 117.71"
      :y2="doorTopHeight1 + 225.66"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-8"
      data-name="inox-gradient"
      :x1="inoxX"
      :y1="doorTopHeight1 + 225.66"
      :x2="doorLeftWidth1 + 53.69"
      :y2="doorTopHeight1 + 225.66"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-9"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 69.8"
      :y1="doorTopHeight1 + 241.66"
      :x2="doorLeftWidth1 + 69.8"
      :y2="doorTopHeight1 + 274.57"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-10"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 69.8"
      :y1="doorTopHeight1 + 190.18"
      :x2="doorLeftWidth1 + 69.8"
      :y2="doorTopHeight1 + 209.66"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-11"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 69.8"
      :y1="doorTopHeight1 + 138.48"
      :x2="doorLeftWidth1 + 69.8"
      :y2="doorTopHeight1 + 157.95"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-12"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 69.8"
      :y1="doorTopHeight1 + 86.77"
      :x2="doorLeftWidth1 + 69.8"
      :y2="doorTopHeight1 + 106.24"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-13"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 69.8"
      :y1="doorTopHeight1 + 21.63"
      :x2="doorLeftWidth1 + 69.8"
      :y2="doorTopHeight1 + 54.54"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="glass-gradient"
      :x1="doorLeftWidth1 + 69.8"
      :y1="doorTopHeight1 + 209.54"
      :x2="doorLeftWidth1 + 69.8"
      :y2="doorTopHeight1 + 241.78"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient
      id="glass-gradient-2"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 69.8"
      :y1="doorTopHeight1 + 157.95"
      :x2="doorLeftWidth1 + 69.8"
      :y2="doorTopHeight1 + 190.18"
      xlink:href="#glass-gradient"/>
    <linearGradient
      id="glass-gradient-3"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 69.8"
      :y1="doorTopHeight1 + 106.24"
      :x2="doorLeftWidth1 + 69.8"
      :y2="doorTopHeight1 + 138.48"
      xlink:href="#glass-gradient"/>
    <linearGradient
      id="glass-gradient-4"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 69.8"
      :y1="doorTopHeight1 + 54.54"
      :x2="doorLeftWidth1 + 69.8"
      :y2="doorTopHeight1 + 86.77"
      xlink:href="#glass-gradient"/>
    <!-- </g> -->
    <!-- <g v-if="showVariant === 'inside'">
      <svg:style>
        .cls-10, .cls-11, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
          stroke: #aeaeae;
          stroke-miterlimit: 10;
          stroke-width: 0.25px;
        }

        .cls-4 {
          fill: url(#inox-gradient);
        }

        .cls-5 {
          fill: url(#inox-gradient);
        }

        .cls-6 {
          fill: url(#glass-pattern);
        }

        .cls-7 {
          fill: url(#glass-pattern);
        }

        .cls-8 {
          fill: url(#inox-gradient-3);
        }

        .cls-9 {
          fill: url(#inox-gradient);
        }

        .cls-10 {
          fill: url(#glass-pattern);
        }

        .cls-11 {
          fill: url(#glass-pattern);
        }
        .handle { fill: url(#handle-gradient); }
      </svg:style>
      <linearGradient id="inox-gradient"
        :x1="doorLeftWidth1 + 70.81"
        :y1="doorTopHeight1 + 93.13"
        :x2="doorLeftWidth1 + 70.81"
        :y2="doorTopHeight1 + 50.73"
        xlink:href="#handle-gradient"/>
      <linearGradient id="inox-gradient-2" data-name="inox"
        :x1="doorLeftWidth1 + 70.81"
        :y1="doorTopHeight1 + 145.43"
        :x2="doorLeftWidth1 + 70.81"
        :y2="doorTopHeight1 + 103.02"
        xlink:href="#handle-gradient"/>
      <linearGradient id="glass-gradient"
        :x1="doorLeftWidth1 + 70.81"
        :y1="doorTopHeight1 + 88.1"
        :x2="doorLeftWidth1 + 70.81"
        :y2="doorTopHeight1 + 55.58"
        gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#f4f4f4"/>
        <stop offset="0.5" stop-color="#dee8e8"/>
        <stop offset="1" stop-color="#d1cece"/>
      </linearGradient>
      <linearGradient id="glass-gradient-2" data-name="glass-gradient"
        :x1="doorLeftWidth1 + 70.81"
        :y1="doorTopHeight1 + 140.41"
        :x2="doorLeftWidth1 + 70.81"
        :y2="doorTopHeight1 + 107.89"
        xlink:href="#glass-gradient"/>
      <linearGradient id="inox-gradient-3" data-name="inox"
        :x1="doorLeftWidth1 + 70.81"
        :y1="doorTopHeight1 + 250.05"
        :x2="doorLeftWidth1 + 70.81"
        :y2="doorTopHeight1 + 207.64"
        xlink:href="#handle-gradient"/>
      <linearGradient id="inox-gradient-4" data-name="inox"
        :x1="doorLeftWidth1 + 70.81"
        :y1="doorTopHeight1 + 197.74"
        :x2="doorLeftWidth1 + 70.81"
        :y2="doorTopHeight1 + 155.33"
        xlink:href="#handle-gradient"/>
      <linearGradient id="glass-gradient-3" data-name="glass-gradient"
        :x1="doorLeftWidth1 + 70.81"
        :y1="doorTopHeight1 + 245.18"
        :x2="doorLeftWidth1 + 70.81"
        :y2="doorTopHeight1 + 212.67"
        xlink:href="#glass-gradient"/>
      <linearGradient id="glass-gradient-4" data-name="glass-gradient"
        :x1="doorLeftWidth1 + 70.81"
        :y1="doorTopHeight1 + 192.88"
        :x2="doorLeftWidth1 + 70.81"
        :y2="doorTopHeight1 + 160.37"
        xlink:href="#glass-gradient"/>
      <filter id="inset-shadow">
          <feOffset dx="0" dy="0"/>
          <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
          <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
          <feFlood flood-color="black" flood-opacity="1" result="color"/>
          <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
          <feComponentTransfer in="shadow" result="shadow">
            <feFuncA type="linear" slope="1.25"/>
          </feComponentTransfer>
          <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
      </filter>
    </g> -->
    <g id="S27">
      <!-- outside BEGIN -->
      <!-- <g v-if="showVariant === 'outside'"> -->
        <rect
          id="inox"
          v-if="showInox"
          class="cls-104"
          :x="doorLeftWidth1 + 85.92"
          :y="doorTopHeight1 + 69.76"
          :width="inoxW"
          height="1.79"/>
        <rect
          id="inox-2"
          v-if="showInox"
          data-name="inox"
          class="cls-105"
          :x="inoxX"
          :y="doorTopHeight1 + 69.76"
          :width="inoxW"
          height="1.79"/>
        <rect
          id="inox-3"
          v-if="showInox"
          data-name="inox"
          class="cls-106"
          :x="doorLeftWidth1 + 85.92"
          :y="doorTopHeight1 + 121.46"
          :width="inoxW"
          height="1.79"/>
        <rect
          id="inox-4"
          v-if="showInox"
          data-name="inox"
          class="cls-107"
          :x="inoxX"
          :y="doorTopHeight1 + 121.46"
          :width="inoxW"
          height="1.79"/>
        <rect
          id="inox-5"
          v-if="showInox"
          data-name="inox"
          class="cls-108"
          :x="doorLeftWidth1 + 85.92"
          :y="doorTopHeight1 + 173.17"
          :width="inoxW"
          height="1.79"/>
        <rect
          id="inox-6"
          v-if="showInox"
          data-name="inox"
          class="cls-109"
          :x="inoxX"
          :y="doorTopHeight1 + 173.17"
          :width="inoxW"
          height="1.79"/>
        <rect
          id="inox-7"
          v-if="showInox"
          data-name="inox"
          class="cls-110"
          :x="doorLeftWidth1 + 85.92"
          :y="doorTopHeight1 + 224.65"
          :width="inoxW"
          height="2.01"/>
        <rect
          id="inox-8"
          v-if="showInox"
          data-name="inox"
          class="cls-111"
          :x="inoxX"
          :y="doorTopHeight1 + 224.65"
          :width="inoxW"
          height="2.01"/>
        <rect
          id="inox-9"
          v-if="showInox"
          data-name="inox"
          class="cls-112"
          :x="doorLeftWidth1 + 68.91"
          :y="inoxY2"
          width="1.79"
          :height="inoxH"/>
        <rect
          id="inox-10"
          v-if="showInox"
          data-name="inox"
          class="cls-113"
          :x="doorLeftWidth1 + 68.91"
          :y="doorTopHeight1 + 190.18"
          width="1.79"
          height="19.47"/>
        <rect
          id="inox-11"
          v-if="showInox"
          data-name="inox"
          class="cls-114"
          :x="doorLeftWidth1 + 68.91"
          :y="doorTopHeight1 + 138.48"
          width="1.79"
          height="19.47"/>
        <rect
          id="inox-12"
          v-if="showInox"
          data-name="inox"
          class="cls-115"
          :x="doorLeftWidth1 + 68.91"
          :y="doorTopHeight1 + 86.77"
          width="1.79"
          height="19.47"/>
        <rect
          id="inox-13"
          v-if="showInox"
          data-name="inox"
          class="cls-116"
          :x="doorLeftWidth1 + 68.91"
          :y="inoxY1"
          width="1.79"
          :height="inoxH"/>
        <rect
          id="glass"
          class="cls-117"
          :x="doorLeftWidth1 + 53.69"
          :y="doorTopHeight1 + 209.54"
          width="32.24"
          height="32.23"/>
        <rect
          id="glass-2"
          data-name="glass"
          class="cls-118"
          :x="doorLeftWidth1 + 53.69"
          :y="doorTopHeight1 + 157.95"
          width="32.24"
          height="32.23"/>
        <rect
          id="glass-3"
          data-name="glass"
          class="cls-119"
          :x="doorLeftWidth1 + 53.69"
          :y="doorTopHeight1 + 106.24"
          width="32.24"
          height="32.23"/>
        <rect
          id="glass-4"
          data-name="glass"
          class="cls-120"
          :x="doorLeftWidth1 + 53.69"
          :y="doorTopHeight1 + 54.54"
          width="32.24"
          height="32.23"/>
      <!-- </g> -->
      <!-- outside END -->
      <!-- inside BEGIN -->
      <!-- <g v-if="showVariant === 'inside'">
        <polyline id="inox" v-if="showInox"
                  data-name="inox"
                  class="cls-4"
                  :points="`${doorLeftWidth1 + 92.02} ${doorTopHeight1 + 50.73} ${doorLeftWidth1 + 49.61} ${doorTopHeight1 + 50.73} ${doorLeftWidth1 + 49.61} ${doorTopHeight1 + 93.13} ${doorLeftWidth1 + 92.02} ${doorTopHeight1 + 93.13} ${doorLeftWidth1 + 92.02} ${doorTopHeight1 + 50.73}`"/>
        <polyline id="inox-2" v-if="showInox"
                  data-name="inox"
                  class="cls-5"
                  :points="`${doorLeftWidth1 + 92.02} ${doorTopHeight1 + 103.02} ${doorLeftWidth1 + 49.61} ${doorTopHeight1 + 103.02} ${doorLeftWidth1 + 49.61} ${doorTopHeight1 + 145.43} ${doorLeftWidth1 + 92.02} ${doorTopHeight1 + 145.43} ${doorLeftWidth1 + 92.02} ${doorTopHeight1 + 103.02}`"/>
        <polyline filter="url(#inset-shadow)" id="glass"
                  class="cls-6"
                  :points="`${doorLeftWidth1 + 87.07} ${doorTopHeight1 + 55.58} ${doorLeftWidth1 + 54.56} ${doorTopHeight1 + 55.58} ${doorLeftWidth1 + 54.56} ${doorTopHeight1 + 88.1} ${doorLeftWidth1 + 87.07} ${doorTopHeight1 + 88.1} ${doorLeftWidth1 + 87.07} ${doorTopHeight1 + 55.58}`"/>
        <polyline filter="url(#inset-shadow)" id="glass-2"
                  data-name="glass"
                  class="cls-7"
                  :points="`${doorLeftWidth1 + 87.07} ${doorTopHeight1 + 107.89} ${doorLeftWidth1 + 54.56} ${doorTopHeight1 + 107.89} ${doorLeftWidth1 + 54.56} ${doorTopHeight1 + 140.41} ${doorLeftWidth1 + 87.07} ${doorTopHeight1 + 140.41} ${doorLeftWidth1 + 87.07} ${doorTopHeight1 + 107.89}`"/>
        <polyline id="inox-3" v-if="showInox"
                  data-name="inox"
                  class="cls-8"
                  :points="`${doorLeftWidth1 + 49.61} ${doorTopHeight1 + 250.05} ${doorLeftWidth1 + 92.02} ${doorTopHeight1 + 250.05} ${doorLeftWidth1 + 92.02} ${doorTopHeight1 + 207.64} ${doorLeftWidth1 + 49.61} ${doorTopHeight1 + 207.64} ${doorLeftWidth1 + 49.61} ${doorTopHeight1 + 250.05}`"/>
        <polyline id="inox-4" v-if="showInox"
                  data-name="inox"
                  class="cls-9"
                  :points="`${doorLeftWidth1 + 49.61} ${doorTopHeight1 + 197.74} ${doorLeftWidth1 + 92.02} ${doorTopHeight1 + 197.74} ${doorLeftWidth1 + 92.02} ${doorTopHeight1 + 155.33} ${doorLeftWidth1 + 49.61} ${doorTopHeight1 + 155.33} ${doorLeftWidth1 + 49.61} ${doorTopHeight1 + 197.74}`"/>
        <polyline filter="url(#inset-shadow)" id="glass-3"
                  data-name="glass"
                  class="cls-10"
                  :points="`${doorLeftWidth1 + 54.56} ${doorTopHeight1 + 245.18} ${doorLeftWidth1 + 87.07} ${doorTopHeight1 + 245.18} ${doorLeftWidth1 + 87.07} ${doorTopHeight1 + 212.67} ${doorLeftWidth1 + 54.56} ${doorTopHeight1 + 212.67} ${doorLeftWidth1 + 54.56} ${doorTopHeight1 + 245.18}`"/>
        <polyline filter="url(#inset-shadow)" id="glass-4"
                  data-name="glass"
                  class="cls-11"
                  :points="`${doorLeftWidth1 + 54.56} ${doorTopHeight1 + 192.88} ${doorLeftWidth1 + 87.07} ${doorTopHeight1 + 192.88} ${doorLeftWidth1 + 87.07} ${doorTopHeight1 + 160.37} ${doorLeftWidth1 + 54.56} ${doorTopHeight1 + 160.37} ${doorLeftWidth1 + 54.56} ${doorTopHeight1 + 192.88}`"/>
      </g> -->
      <!-- inside END -->
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
    'showVariant',
    'laminationId',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
    }
  },
  computed: {
    inoxH() {
      var hh = this.doorTopHeight1 + 54.5 - this.doorTopHeight;
      return this.showBg ?
        hh - this.inoxOffset :
        hh - this.leafOffset
    },
    inoxX() {
      return this.showBg ?
        this.doorLeftWidth + this.inoxOffset :
        this.doorLeftWidth + this.leafOffset
    },
    inoxY1() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset:
        this.doorTopHeight + this.leafOffset
    },
    inoxY2() {
      return this.doorTopHeight1 + 241.66
    },
    inoxW() {
      return this.showBg ?
        this.doorWidth/2 - this.inoxOffset - 32.24/2 :
        this.doorWidth/2 - this.leafOffset - 32.24/2
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
  },
}
</script>
